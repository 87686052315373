#courseworkText {
  padding-left: 10%;
  padding-top: 8%;
  text-align: left;
}

#courseworkContainer {
  padding-bottom: 3%;
}

.webTech {
  padding-bottom: 5%;
}

.frameworks {
  padding-bottom: 5%;
}

.softwareTools {
  padding-bottom: 5%;
}
