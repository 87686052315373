#nav-items {
  text-transform: capitalize;
  width: 120px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #ba68c8 !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
}

#logoText {
  color: #fc8370;
  font-weight: 550;
}
